// Footer.jsx
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import StatefulImage from '../component/StatefulImage.jsx';

import LanguageButton from '../container/LanguageButton.js';
import MailTo from '../container/MailTo.js';

import useMessageDetailType from '../hook/useMessageDetailType.js';

import { ButtonId } from '../resource/mixpanel.js';
import { getShouldShowFooter } from '../resource/getShouldShowFooter.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { getIsValidLink as getIsCompletedUrl } from '../resource/getInternalLink.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import getResourceUrl from '../resource/getResourceUrl.js';

import useBecomeCreatorLink from '../hook/useBecomeCreatorLink.js';

import media from '../style/media.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import { color } from '../style/variables.js';

import AsacpIconSource from '../../img/img_asagp.svg';
import RtaIconSource from '../../img/img_rta.svg';
import CopyIcon from '../../img/ic-copy-link.svg';

const isServer = typeof window === 'undefined';

const CONTACT_SUPPORT_LINK = '/contact-support'; // TODO: remote config

export const Footer = ({
  publicLogoUrlPath = '',
  dmcaId = '',
  rtaUrl = '',
  socialMediaLinks: propsSocialMediaLinks = [],
  aboutLinks: propsAboutLinks = [],
  supportLinks: propsSupportLinks = [],
  workLinks: propsWorkLinks = [],
  discoverLinks: propsDiscoverLinks = [],
  staticContext = {},
  t,
}) => {
  const { pathname, search } = useLocation();
  const becomeCreatorLink = useBecomeCreatorLink();
  const getSectionItemData = useCallback(
    ({ title: titleI18nKey, link, mixpanel, icon }) => {
      const title = t(titleI18nKey, { ns: TranslationNamespace.SETTINGS });
      const { ['button.id']: buttonId, ...extraProps } = mixpanel || {};
      const result = {
        title,
        'data-element_id': buttonId,
        'data-tracking_payload': {
          ...extraProps,
        },
      };
      if (icon) {
        result.icon = getResourceUrl({
          path: publicLogoUrlPath,
          endpoint: `/${icon}.svg`,
          resourceType: PUBLIC,
        });
      }
      const isCompletedUrl = getIsCompletedUrl({
        link,
      });
      if (isCompletedUrl) {
        result.href = link;
      } else {
        result.to = link;
      }
      return result;
    },
    [publicLogoUrlPath, t]
  );
  const aboutLinks = useMemo(
    () => propsAboutLinks.map(getSectionItemData),
    [getSectionItemData, propsAboutLinks]
  );
  const supportLinks = useMemo(
    () => propsSupportLinks.map(getSectionItemData),
    [getSectionItemData, propsSupportLinks]
  );
  const workLinks = useMemo(() => {
    const links = propsWorkLinks.map(getSectionItemData);
    if (becomeCreatorLink) {
      const { isInternalLink, link } = becomeCreatorLink;
      const item = {
        title: t('swaggers_wanted', { ns: TranslationNamespace.SETTINGS }),
        isActive: getIsMatchedPathname({ pathname, dataKey: 'isOnJoin' }),
        'data-element_id': ButtonId.Join.ButtonBecomeCreator,
      };
      if (isInternalLink) {
        item.to = link;
      } else {
        item.href = link;
      }
      links.push(item);
    }
    return links;
  }, [becomeCreatorLink, getSectionItemData, pathname, propsWorkLinks, t]);
  const discoverLinks = useMemo(
    () => propsDiscoverLinks.map(getSectionItemData),
    [getSectionItemData, propsDiscoverLinks]
  );
  const socialMediaLinks = useMemo(
    () => propsSocialMediaLinks.map(getSectionItemData),
    [getSectionItemData, propsSocialMediaLinks]
  );
  const renderSectionItem = item => {
    const { href, to, title } = item;
    if (CONTACT_SUPPORT_LINK === to) {
      return (
        <SectionMailToItem key={'contact-support'} {...item}>
          {title}
        </SectionMailToItem>
      );
    }
    return href ? (
      <SectionExternalLinkItem
        key={href}
        target="_blank"
        rel="noopener noreferrer"
        {...item}
      >
        {title}
      </SectionExternalLinkItem>
    ) : to ? (
      <SectionLinkItem key={to} isActive={pathname === to} {...item}>
        {title}
      </SectionLinkItem>
    ) : (
      <SectionButtonItem key={href} {...item}>
        {title}
      </SectionButtonItem>
    );
  };
  const ssrHostname = staticContext?.requestHeaders?.hostname || 'swag.live';
  const hostname = !isServer ? window.location.hostname : ssrHostname;
  const locationHref = `https://${hostname}${pathname}${search}`;
  const messageLayoutType = useMessageDetailType();
  // Should append refurl on DMCA href query string.
  // Souce code: https://images.dmca.com/Badges/DMCABadgeHelper.min.js
  const dmcaHref = `https://www.dmca.com/Protection/Status.aspx?ID=${dmcaId}&refurl=${locationHref}`;
  if (
    !getShouldShowFooter({
      pathname,
      messageLayoutType,
    })
  ) {
    return null;
  }
  return (
    <StyledFooter>
      <ContentWrapper>
        <Content>
          <Section>
            <SectionTitle>
              {t('about', { ns: TranslationNamespace.SETTINGS })}
            </SectionTitle>
            {aboutLinks.map(renderSectionItem)}
          </Section>
          <Section>
            <SectionTitle>
              {t('footer_support', { ns: TranslationNamespace.SETTINGS })}
            </SectionTitle>
            {supportLinks.map(renderSectionItem)}
          </Section>
          <Section>
            <SectionTitle>
              {t('footer_work', { ns: TranslationNamespace.SETTINGS })}
            </SectionTitle>
            {workLinks.map(renderSectionItem)}
          </Section>
          {discoverLinks.length > 0 && (
            <Section>
              <SectionTitle>
                {t('footer_discover', { ns: TranslationNamespace.SETTINGS })}
              </SectionTitle>
              {discoverLinks.map(renderSectionItem)}
            </Section>
          )}
        </Content>
        <Content isLanguageButton>
          <LanguageButton modalViewRootId={'footer-language-menu'} />
        </Content>
      </ContentWrapper>
      <SubFooterWrapper>
        <SubFooter>
          <SocialMediaLinksSection>
            {socialMediaLinks.map(({ href, icon, title, onClick }) => (
              <SocialMediaLink
                key={href}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
              >
                <SocialMediaIconWrapper>
                  <StatefulImage>
                    <SocialMediaIcon
                      data-key="loading"
                      src={CopyIcon}
                      alt={title}
                    />
                    <SocialMediaIcon
                      data-key="error"
                      src={CopyIcon}
                      alt={title}
                    />
                    <SocialMediaIcon data-key="target" src={icon} alt={title} />
                  </StatefulImage>
                </SocialMediaIconWrapper>
              </SocialMediaLink>
            ))}
          </SocialMediaLinksSection>
          <AssociationLinksSection>
            <AssociationLinks>
              {/* Ref. http://www.rtalabel.org/index.php?content=rtaplus&step=download */}
              <AssociationLink
                href={rtaUrl || '#'}
                target={rtaUrl ? '_blank' : '_self'}
                rel="noopener noreferrer"
                data-element_id={ButtonId.Setting.ButtonRta}
              >
                <RtaIcon src={RtaIconSource} alt="RTA" width="38" height="14" />
              </AssociationLink>
              <AssociationLink
                href="https://www.asacp.org"
                target="_blank"
                rel="noopener noreferrer"
                data-element_id={ButtonId.Setting.ButtonAsacp}
              >
                <AsacpIcon
                  src={AsacpIconSource}
                  alt="ASACP"
                  width="65"
                  height="14"
                />
              </AssociationLink>
              <AssociationLink
                href={dmcaHref}
                title="DMCA.com Protection Status"
                target="_blank"
                className="dmca-badge"
                data-element_id={ButtonId.Setting.ButtonDmca}
              >
                <DmcaIcon
                  src={`https://images.dmca.com/Badges/DMCA_logo-grn-btn100w.png?ID=${dmcaId}`}
                  alt="DMCA.com Protection Status"
                  width="50"
                  height="18"
                />
              </AssociationLink>
            </AssociationLinks>
            <Copyright>
              {t('footer_copyright', { ns: TranslationNamespace.SETTINGS })}
            </Copyright>
          </AssociationLinksSection>
        </SubFooter>
      </SubFooterWrapper>
    </StyledFooter>
  );
};

Footer.propTypes = {
  publicLogoUrlPath: PropTypes.string,
  dmcaId: PropTypes.string,
  rtaUrl: PropTypes.string,
  socialMediaLinks: PropTypes.array,
  aboutLinks: PropTypes.array,
  supportLinks: PropTypes.array,
  workLinks: PropTypes.array,
  discoverLinks: PropTypes.array,
  location: PropTypes.object,
  staticContext: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const StyledFooter = styled.footer``;

const ContentWrapper = styled.div`
  background-color: ${color.neutral[10]};
  padding-top: 68px;
  padding-bottom: 20px;
  ${media.mobile`
    padding-top: 64px;
    padding-bottom: 40px;
  `};
`;

const ContentMaxWidth = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet`
    max-width: 720px;
  `};
  ${media.mobile`
    width: 100%;
    max-width: none;
    flex-direction: column;
    justify-content: center;
  `};
`;

const Content = styled.div.attrs(({ isLanguageButton }) => ({
  style: {
    paddingLeft: `${isLanguageButton ? 0 : 16}px`,
    paddingRight: `${isLanguageButton ? 0 : 16}px`,
  },
}))`
  ${ContentMaxWidth};
  margin-bottom: 60px;
  align-items: flex-start;
  ${media.mobile`
    margin-bottom: 56px;
    align-items: center;
  `};
  :last-child {
    margin-bottom: 0px;
  }
`;

const Section = styled.div`
  max-width: 220px;
  ${media.tablet`
    max-width: 180px;
  `};
  ${media.mobile`
    margin-bottom: 60px;
    max-width: none;
    text-align: center;
    :last-child {
      margin-bottom: 0px;
    }
  `};
`;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: ${color.neutral[100]};
`;

const SectionItemStyle = css`
  margin-bottom: 12px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: ${({ isActive }) => (isActive ? color.tealBlue : color.neutral[75])};
  text-align: left;
  :last-child {
    margin-bottom: 0px;
  }
  @media (hover: hover) {
    :hover {
      color: ${color.tealBlue};
      opacity: 1;
    }
  }
  ${media.mobile`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  `};
`;

const SectionMailToItem = styled(MailTo)`
  ${SectionItemStyle};
`;

const SectionButtonItem = styled.button`
  ${ResetButtonStyle};
  ${SectionItemStyle};
`;

const SectionExternalLinkItem = styled.a`
  ${SectionItemStyle};
`;

const SectionLinkItem = styled(({ isActive, ...restProps }) => (
  <Link {...restProps} />
))`
  ${SectionItemStyle};
`;

const SubFooterWrapper = styled.div`
  background-color: ${color.neutral[0]};
  padding-top: 32px;
  padding-bottom: 80px;
  ${media.mobile`
    padding: 40px 28px 60px;
  `};
`;

const SubFooter = styled.div`
  ${ContentMaxWidth};
  align-items: flex-start;
  ${media.mobile`
    align-items: center;
    justify-content: center;
  `};
`;

const SocialMediaLinksSection = styled.div`
  ${media.mobile`
    margin-bottom: 40px;
  `};
`;

const SocialMediaLink = styled.a`
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  :last-child {
    margin-right: 0px;
  }
`;

const SocialMediaIconWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

const SocialMediaIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const AssociationLinksSection = styled.div``;

const AssociationLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.mobile`
    justify-content: center;
  `};
`;

const Copyright = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: ${color.neutral[55]};
  text-align: right;
  white-space: pre-wrap;
  ${media.mobile`
    text-align: center;
  `};
`;

const AssociationLink = styled.a`
  margin-right: 8px;
  :last-child {
    margin-right: 0px;
  }
`;

const RtaIcon = styled.img`
  width: 38px;
  height: 14px;
  object-fit: contain;
`;

const AsacpIcon = styled.img`
  width: 65px;
  height: 14px;
  object-fit: contain;
`;

const DmcaIcon = styled.img`
  width: 50px;
  height: 18px;
  object-fit: contain;
`;

export default withTranslation()(Footer);
