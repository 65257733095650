// submitEmailToSupport.js
'use strict';
import {
  REPORT_EMAIL,
  REPORT_EMAIL_MAILTO,
  REPORT_EMAIL_SUBJECT,
  REPORT_EMAIL_SUBJECT_PLACEHOLDER,
  REPORT_EMAIL_BODY,
} from '../RemoteConfigKeys.js';
import env from '../resource/env.js';
import getUserAgent, {
  getIsInPwa,
  userAgentString,
} from '../resource/getUserAgent.js';
import getOperationData from '../selector/getOperationData.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getIsCriticalChannel from '../resource/getIsCriticalChannel.js';

/**
 * Submit email
 * @kind action
 * @param {string} [{subject}] - email subject.
 * @param {string} [{body = ''}] - email body.
 * @return {Promise} Action promise.
 */
const submitEmailToSupport =
  ({
    subject,
    body = '',
    skipSubscribedChannel = false,
    skipNonCriticalChannel = false,
  } = {}) =>
  async (dispatch, getState) => {
    const ua = getUserAgent();
    const { name: browserName, version: browserVersion } = ua.browser;
    const { name: osName, version: osVersion } = ua.os;
    const isPWA = getIsInPwa();

    const state = getState();

    const targetEmail = getRemoteConfigData(state, REPORT_EMAIL);
    // The reason we add not only `mailtoTemplate`,
    // but also `subjectTemplate` and `subjectPlaceholder`,
    // is that the function caller could set custom subject.
    // Consequently, the strings such as 'Bug Report' shouldn't always be in `mailtoTemplate`.
    const mailtoTemplate = getRemoteConfigData(state, REPORT_EMAIL_MAILTO);
    const subjectTemplate = getRemoteConfigData(state, REPORT_EMAIL_SUBJECT);
    const subjectPlaceholder = getRemoteConfigData(
      state,
      REPORT_EMAIL_SUBJECT_PLACEHOLDER
    );
    const contentTemplate = getRemoteConfigData(state, REPORT_EMAIL_BODY);
    const username = getMeData(state, 'username') || 'Visitor';
    const pusherConnectionState = getOperationData(
      state,
      ['pusher', 'connection'],
      'state'
    );
    const pusherSubscribedStates = getOperationData(
      state,
      ['pusher'],
      'channels'
    );
    const version = env.TAG_NAME || env.BRANCH_NAME || 'local';
    const shaString = env.SHORT_SHA ? `(${env.SHORT_SHA})` : '';

    let filteredPusherSubscribedStates = {};
    if (skipNonCriticalChannel) {
      Object.keys(pusherSubscribedStates)?.forEach(channelName => {
        if (getIsCriticalChannel({ channelName })) {
          filteredPusherSubscribedStates[channelName] =
            pusherSubscribedStates[channelName];
        }
      });
    } else {
      filteredPusherSubscribedStates = pusherSubscribedStates;
    }

    const bodyWithPusherChannelState = contentTemplate
      .replace(/\\n/g, '\n')
      .replace(/{USERNAME}/g, username)
      .replace(/{SOURCE}/g, location.href)
      .replace(/{VERSION}/g, `${version}${shaString}`)
      .replace(
        /{BROWSER}/g,
        `${browserName} ${browserVersion} ${isPWA ? 'PWA' : ''}`
      )
      .replace(/{OS}/g, `${osName} ${osVersion}`)
      .replace(/{USER_AGENT}/g, userAgentString)
      .replace(/{PUSHER_STATUS}/g, pusherConnectionState)
      .replace(
        /{PUSHER_SUBSCRIBE_CHANNEL}/g,
        JSON.stringify(filteredPusherSubscribedStates, null, '\t')
      );

    const mailContent = skipSubscribedChannel
      ? body
      : bodyWithPusherChannelState.replace('{USER_INPUT}', body);
    const mailSubjectPlaceholder = subjectPlaceholder.replace(
      '{USERNAME}',
      username
    );
    const mailSubject = subjectTemplate.replace(
      '{SUBJECT}',
      subject || mailSubjectPlaceholder
    );
    const mailto = mailtoTemplate
      .replace('{MAILTO}', targetEmail)
      .replace('{EMAIL_SUBJECT}', mailSubject)
      .replace('{EMAIL_CONTENT}', encodeURIComponent(mailContent))
      .toString();
    const url = new URL(mailto).toString();

    if (isPWA) {
      location.href = url;
    } else {
      window.open(url);
    }
  };

export default submitEmailToSupport;
