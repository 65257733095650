// getIsCriticalChannel.js
'use strict';

/**
 * Get is critical channel
 * @param {string} channelName - channel Name.
 */

const criticalChannelRegexes = [
  /^presence-enc-client@.+/,
  /^presence-enc-user@.+/,
  /^private-enc-stream@([a-z\d]+)$/i,
  // presence-enc-stream-viewer@STREAMER_ID.PRESET.VIEWER_ID
  /^presence-enc-stream-viewer@([a-z\d]+)\.([a-z]+)\.([a-z\d]+)$/i,
  /^presence-enc-asset@([a-z\d]+)$/i,
  /^presence-enc-message@([a-z\d]+)$/i,
  /^private-enc-campaign@([a-z\d_]+)$/i,
];

const getIsCriticalChannel = ({ channelName }) =>
  !!criticalChannelRegexes.find(regex => regex.test(channelName));

export default getIsCriticalChannel;
