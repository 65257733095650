// MailTo.js
'use strict';
import { connect } from 'react-redux';
import MailTo from '../component/MailTo.jsx';

import getMeData from '../selector/getMeData.js';
import submitEmailToSupport from '../action/submitEmailToSupport.js';

function mapStateToProps(state) {
  return {
    hasIntercomUserHash: !!getMeData(state, 'intercomUserHash'),
    hasClientId: !!getMeData(state, 'clientId'),
  };
}

const mapDispatchToProps = dispatch => ({
  submitEmailToSupport: data => dispatch(submitEmailToSupport(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailTo);
